<template>
  <div>
    <div class="banner_wrap">
      <img :src="imgListurl">
      <!-- <img src="https://store.daline.com.cn/prod/dalineweb/daloss/DalineDev/dalPUs/17771874636/UploadServer//UploadServer//1630304127705dal-image@3x.png"> -->
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  DALINEWEB_IMGCENTER_V0X0X1_getImgList,
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo
} from '../api/dalapi.js'
export default {

  name: 'dal-comp-banner',
  mounted () {

  },
  data () {
    return {
      imgListurl: '',
    }
  },
  methods: {
    async getimginfo () {

    }
  },
  watch: {
    '$route': {
      deep: true,
      immediate: true,
      async handler (val) {
        let path = val.path
        let dicInput = {
          records: 1
        }
        const articleID = val.query.articleID
        console.log(val, "val", articleID);
        if (articleID != undefined) {
          let dicinput = {
            articleID: articleID
          }
          const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo(dicinput)
          const articleInfo = res.content.resContent[0]
          this.imgListurl = articleInfo.articleBnner
        }
        else {
          switch (path) {
            case "/Privatetrafficconstruction":
              dicInput.imgType = 2
              break;
            case "/Digitalcapitalization":
              dicInput.imgType = 3
              break;
            case "/Integratedbusinessdevelopmentplatform":
              dicInput.imgType = 4
              break;
            case "/edgepower":
              dicInput.imgType = 5
              break;
            case "/Aboutus":
              dicInput.imgType = 6
              break;
            case "/article":
              console.log(articleID);
              break
          }
          if(dicInput.imgType === 5){
            this.imgListurl = require("@/assets/banner_ep2.png")
          }else{
            const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList(dicInput);
            let imgListurl = resImgList.content
            this.imgListurl = imgListurl.imgList[0].imgURL
          }
        }
      },

    }
  },

}
</script>

<style scoped>
.banner_wrap {
  width: 100vw;
  display: flex;
}
.banner_wrap img {
  width: 100%;
}
</style>